<template>
    <div v-if="isDisplayingAccounts">
        <div class="flex flex-col space-y-2 border p-5 mt-8 sticky top-2">
            <Accounts
                :visible="isDisplayingAccounts"
                :client-id="clientId"
                :client-info="clientInfo"
                :selected-accounts-data="selectedAccounts"
                :titles-data="titlesData"
                :annual-report-id="annualReportId"
                @show-move-accounts-modal="showMoveAccountModal"
                @update-income-statement-details="fetchIncomeStatementDetails"
                :is-period="isPeriod"
                :income-statement-data="incomeStatementData"
            />
            <el-button @click="closeAccountsSection()">Stäng</el-button>
        </div>
        <MoveAccounts
            ref="moveAccountsModal"
            :visible="modalMoveAccountVisible"
            :client-id="clientId"
            :client-info="clientInfo"
            :selected-accounts-data="selectedAccounts"
            :selected-account="selectedAccount"
            :income-statement-data="incomeStatementData"
            :titles-data="titlesData"
            :annual-report-id="annualReportId"
            @close="modalMoveAccountVisible = false"
            @update-income-statement-details="fetchIncomeStatementDetails"
            @close-accounts-section-when-moved="closeAccountsSectionWhenMoved"
            :is-period="isPeriod"
        />
    </div>
</template>

<script>
export default {
    props: {
        isDisplayingAccounts: {
            type: Boolean,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        selectedAccounts: {
            type: Array,
        },
        titlesData: {
            type: Array,
        },
        annualReportId: {
            type: String,
        },
        incomeStatementData: {
            type: Object,
        },
        isPeriod: {
            type: Boolean,
        },
    },
    data() {
        return {
            modalMoveAccountVisible: false,
            selectedAccount: {},
        };
    },
    components: {
        Accounts: () => import("./ShowAccounts.vue"),
        MoveAccounts: () => import("./MoveAccounts.vue"),
    },
    methods: {
        showMoveAccountModal(account) {
            this.selectedAccount = account;
            this.modalMoveAccountVisible = true;
            this.$nextTick(() => {
                this.$refs.moveAccountsModal.$el.focus();
            });
        },
        fetchIncomeStatementDetails() {
            this.$emit("update-income-statement-details");
        },
        closeAccountsSection() {
            this.$emit("update-is-displaying-accounts", false);
        },
        closeAccountsSectionWhenMoved(newValue) {
            this.$emit("update-is-displaying-accounts", newValue);
        },
    },
};
</script>
